import '../styles/utils/popupAlert.css';

// アラートを表示
const popupAlert = (message: string, color: string) => {
    const alertBox = document.createElement('div');
    alertBox.className = 'custom-alert';
    alertBox.style.backgroundColor = color;
    alertBox.textContent = message;
    document.body.appendChild(alertBox);
    setTimeout(() => {
        alertBox.remove();
    }, 3000); // 3秒後にアラートを削除
}

export default popupAlert;