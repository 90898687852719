import React, { useState, useEffect } from 'react';
import '../../styles/components/settings/Settings.css';
import LineLoginButton from './LineLoginButton';
import { updateUserData } from '../../api/userApi';
import popupAlert from "../../utils/popupAlert";
import { storage } from '../../utils/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Setting: React.FC = () => {
    // 状態管理（トグルのオン/オフの状態）
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const [emailNotificationEnabled, setEmailNotificationEnabled] = useState(false);
    const [sharingEnabled, setSharingEnabled] = useState(false);
    const [darkModeEnabled, setDarkModeEnabled] = useState(false);

    // ページ読み込み時にlocalStorageから現在のテーマを取得
    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme === 'dark') {
            setDarkModeEnabled(true);
            document.body.classList.add('dark-mode');
        }
    }, []);

    const toggleNotification = () => {
        setNotificationEnabled(!notificationEnabled);
    };

    const toggleEmailNotification = () => {
        setEmailNotificationEnabled(!emailNotificationEnabled);
    };

    const toggleSharing = () => {
        setSharingEnabled(!sharingEnabled);
    };

    const toggleTheme = () => {
        const newThemeState = !darkModeEnabled;
        setDarkModeEnabled(newThemeState);
        document.body.classList.toggle('dark-mode');
        const isDarkMode = document.body.classList.contains('dark-mode');
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    };

    const handleLineLogin = () => {
        // LINEログインの処理をここに実装する
    };

    const handleDeleteAccount = () => {
        // アカウント削除処理の前に確認ダイアログを表示
        const confirmed = window.confirm("本当にアカウントを削除しますか？この操作は取り消せません。");
        if (confirmed) {
            // アカウント削除処理を実装する
        }
    };

    const handleSaveProfile = async () => {
        const username = (document.getElementById('name-input') as HTMLInputElement).value;
        const email = (document.getElementById('email-input') as HTMLInputElement).value;
        const profilePic = (document.getElementById('profile-picture') as HTMLInputElement).files?.[0];

        if (username || email || profilePic) {
            if (!window.confirm("プロフィールを保存しますか？")) {
                return;
            }

            try {
                const formData = new FormData();
                formData.append('username', username);
                formData.append('email', email);
                if (profilePic) {
                    formData.append('profile_pic', profilePic);
                }

                await updateUserData(formData);
                popupAlert("プロフィールを更新しました", "green");
            } catch (error:any) {
                console.error("プロフィールの更新に失敗しました", error);
                //より詳細なエラーを表示
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                } else if (error.request) {
                    console.error(error.request);
                } else {
                    console.error('Error', error.message);
                }
                popupAlert("プロフィールの更新に失敗しました", "red");
            }
        } else {
            popupAlert("どれか一つの項目は更新してください", "red");
        }
    };

    return (
        <div className="settings-content">
            <div className="settings-header">設定</div>

            {/* プロフィール編集 */}
            <div className="profile-edit-header">プロフィール編集</div>
            <div className="profile-edit-section">
                <label className="section-label" htmlFor="name-input">名前</label>
                <div className="input-container">
                    <input type="text" id="name-input" className="text-input" placeholder="名前" />
                </div>
                <label className="section-label" htmlFor="email-input">メールアドレス</label>
                <div className="input-container">
                    <input type="email" id="email-input" className="text-input" placeholder="example@example.com" />
                </div>
                <label className="section-label" htmlFor="profile-picture">プロフィール画像</label>
                <div className="input-container">
                    <input type="file" id="profile-picture" className="file-input" />
                </div>
                <button className="save-button" onClick={handleSaveProfile}>保存</button>
            </div>

            {/* 通知設定 */}
            <div className="notification-header">通知設定</div>
            <div className="notification-section">
                <label className="section-label" htmlFor="notification-toggle">通知を受け取る</label>
                <div className="toggle-container">
                    <div
                        className={`toggle-switch ${notificationEnabled ? 'active' : ''}`}
                        id="notification-toggle"
                        onClick={toggleNotification}
                    >
                        <div className="toggle-knob"></div>
                    </div>
                    <div className="toggle-label" id="notification-label">
                        {notificationEnabled ? 'On' : 'Off'}
                    </div>
                </div>
            </div>

            {/* LINE連携 */}
            <div className="line-integration-header">LINE連携</div>
            <p>LINE連帯を行うと、在庫情報を自動でLINEに通知することができます。</p>
            <LineLoginButton onClick={handleLineLogin} />
            <div className="line-status">
                {/* ここでLINE連携の状態を表示（連携済みなら解除ボタンを表示） */}
                <p>LINE連携済みです</p>
                <button className="unlink-button">連携解除</button>
            </div>

            {/* アカウント削除 */}
            <div className="account-delete-section">
                <button className="delete-button" onClick={handleDeleteAccount}>アカウント削除</button>
            </div>
        </div>
    );
}

export default Setting;
