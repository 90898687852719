import SideBar from "../components/sidebar/SideBar";
import ShareModal from "../components/share/ShareModal";

const SharingPage = () => {
    return (
        <div>
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}}/>
            <ShareModal />
        </div>
    );
}
export default SharingPage;