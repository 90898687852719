import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "../../styles/components/sidebar/SideBar.css";
import { useAuth } from "../../contexts/AuthContext";

interface SideBarComponentProps {
    onSearchClick: () => void;
    onBarcodeClick: () => void;
}

const SideBar: React.FC<SideBarComponentProps> = ({ onSearchClick, onBarcodeClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const sidebarRef = useRef<HTMLDivElement>(null);

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    const isActive = (path: string) => {
        return location.pathname === path ? "active" : "";
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={sidebarRef}>
            <button className="hamburger" onClick={toggleSidebar}>
                ☰
            </button>
            <div className={`sidebar ${isOpen ? "open shadow" : ""}`}>
                <div className="sidebar-item">{user ? user.username : "Your Not Logged in."}</div>
                <Link to="/notifications" className={`sidebar-item ${isActive("/notifications")}`}>📢 お知らせ</Link>
                <a href="#" className={`sidebar-item ${isActive("/search")}`} onClick={onSearchClick}>🔍 在庫検索</a>
                <Link to="/settings" className={`sidebar-item ${isActive("/settings")}`}>⚙️ 設定</Link>
                <div className="sidebar-item spacer"></div>
                <Link to="/inventory" className={`sidebar-item ${isActive("/inventory")}`}>📦 食材管理</Link>
                <a href="#" className={`sidebar-item ${isActive("/barcode")}`} onClick={onBarcodeClick}>📱
                    バーコード追加</a>
                <Link to="/sharing" className={`sidebar-item ${isActive("/sharing")}`}>👥 共有管理</Link>
                <Link to="/shopping" className={`sidebar-item ${isActive("/shopping")}`}>📝 買い物メモ</Link>
                <a href="#" className={`sidebar-item ${isActive("/logout")}`} onClick={handleLogout}>🔓 ログアウト</a>
                <div className="sidebar-item spacer"></div>
                <a href="#" className={`sidebar-item ${isActive("#")}`} >💎 Proにアップグレード</a>
            </div>
        </div>
    );
}

export default SideBar;
