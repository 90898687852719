// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pp-container {
    padding: 40px;
    max-width: 800px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pp-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.pp-container section {
    margin-bottom: 20px;
}

.pp-container h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
}

.pp-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
}

.pp-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.pp-container ul li {
    margin-bottom: 10px;
    color: #666;
}

.pp-container a {
    color: #0066ff;
    text-decoration: none;
}

.pp-container a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".pp-container {\n    padding: 40px;\n    max-width: 800px;\n    margin: auto;\n    background-color: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.pp-container h1 {\n    font-size: 2.5rem;\n    font-weight: bold;\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.pp-container section {\n    margin-bottom: 20px;\n}\n\n.pp-container h2 {\n    font-size: 1.8rem;\n    font-weight: 600;\n    margin-bottom: 10px;\n    color: #444;\n}\n\n.pp-container p {\n    font-size: 1rem;\n    line-height: 1.6;\n    color: #666;\n}\n\n.pp-container ul {\n    list-style-type: disc;\n    margin-left: 20px;\n}\n\n.pp-container ul li {\n    margin-bottom: 10px;\n    color: #666;\n}\n\n.pp-container a {\n    color: #0066ff;\n    text-decoration: none;\n}\n\n.pp-container a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
