import React, { useState, useEffect, useRef } from 'react';
import '../../styles/components/inventory/InventoryList.css';
import AddStockModal from "./AddStockModal";
import EditStockModal from "./EditStockModal";
import {
    getAllInventories,
    getAllCategories,
    deleteInventory,
    increaseQuantity,
    decreaseQuantity,
} from '../../api/inventoryApi';
import { formatDay } from "../../utils/dateFormer";
import popupAlert from "../../utils/popupAlert";

interface InventoryData {
    ID: number;
    user_id: number | null;
    share_id: number | null;
    name: string;
    jan: string;
    category: string;
    price: number;
    quantity: number;
    expiration_date: string;
    stock_image?: string;
}

interface InventoryListProps {
    searchResults?: InventoryData[];
}

const InventoryList: React.FC<InventoryListProps> = ({ searchResults = [] }) => {
    const [products, setProducts] = useState<InventoryData[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<InventoryData | null>(null);
    const [filteredProducts, setFilteredProducts] = useState<InventoryData[]>([]);
    const [actionMenuOpen, setActionMenuOpen] = useState<number | null>(null);
    const actionMenuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        fetchInventories();
        fetchCategories();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        filterProducts();
    }, [products, selectedCategory, searchResults]);

    const fetchInventories = async () => {
        const data = await getAllInventories();
        setProducts(data);
    };

    const fetchCategories = async () => {
        const data = await getAllCategories();
        setCategories(['all', ...data]);
    };

    const handleCategoryChange = (category: string) => {
        setSelectedCategory(category);
    };

    const filterProducts = () => {
        let filtered = searchResults.length > 0
            ? Array.from(new Set(searchResults.map(p => p.ID))).map(id => searchResults.find(p => p.ID === id)!)
            : products;

        if (selectedCategory !== 'all') {
            filtered = filtered.filter(product => product.category === selectedCategory);
        }

        setFilteredProducts(filtered);
    };

    const toggleActionMenu = (productId: number) => {
        setActionMenuOpen(actionMenuOpen === productId ? null : productId);
    };

    const handleOpenAddModal = () => {
        setShowAddModal(true);
    };

    const handleOpenEditModal = (product: InventoryData) => {
        setSelectedProduct(product);
        setShowEditModal(true);
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false);
        fetchInventories();
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedProduct(null);
        fetchInventories();
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (actionMenuRef.current && !actionMenuRef.current.contains(event.target as Node)) {
            setActionMenuOpen(null);
        }
    };

    const handleDeleteInventory = async (id: number) => {
        const confirmed = window.confirm("本当に削除しますか？この操作は元に戻せません。");
        if (confirmed) {
            await deleteInventory(id);
            await fetchInventories();
        }
    };

    const handleIncreaseQuantity = async (id: number) => {
        try {
            await increaseQuantity(id);
            await fetchInventories();
        } catch (error) {
            console.error("在庫数量の更新に失敗しました", error);
            popupAlert("在庫数量の更新に失敗しました", "red");
        }
    };

    const handleDecreaseQuantity = async (id: number) => {
        try {
            await decreaseQuantity(id);
            await fetchInventories();
        } catch (error) {
            console.error("在庫数量の更新に失敗しました", error);
            popupAlert("在庫数量の更新に失敗しました", "red");
        }
    };

    return (
        <div className="inventory-main-content">
            <div className="inventory-header">
                <div className="dropdown">
                    <button className="dropbtn">在庫一覧</button>
                    <div className="dropdown-content">
                        {categories.map((category, index) => (
                            <a key={`category-${index}`} href="#"
                               onClick={() => handleCategoryChange(category)}>{category}</a>
                        ))}
                    </div>
                </div>
                <button className="add-button" onClick={handleOpenAddModal}>＋</button>
            </div>
            <div className="product-list-container">
                <div className="product-list">
                    {filteredProducts.map(product => (
                        <div className="product" key={product.ID} data-category={product.category}>
                            <img className="product-image" src={product.stock_image || ''} alt="商品画像"/>
                            <div className="product-info">
                                <div className="product-name">{product.name}<br/>{product.category}</div>
                                <div className="product-details">
                                    数量：
                                    <button className="quantity-btn"
                                            onClick={() => handleDecreaseQuantity(product.ID)}>-</button>
                                    {' '}
                                    {product.quantity}
                                    {' '}
                                    <button className="quantity-btn"
                                            onClick={() => handleIncreaseQuantity(product.ID)}>+
                                    </button>
                                    <br/>賞味期限：{formatDay(product.expiration_date)}
                                </div>
                                <div className="action-menu" ref={el => {
                                    if (actionMenuOpen === product.ID) {
                                        actionMenuRef.current = el;
                                    }
                                }}>
                                    <button className="action-toggle" onClick={() => toggleActionMenu(product.ID)}>...
                                    </button>
                                    {actionMenuOpen === product.ID && (
                                        <div className="action-content">
                                            <button onClick={() => handleOpenEditModal(product)}>編集</button>
                                            <button onClick={() => handleDeleteInventory(product.ID)}>削除</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showAddModal &&
                <AddStockModal onClose={handleCloseAddModal} initialData={null} onInventoryAdded={fetchInventories}/>}
            {showEditModal && selectedProduct && (
                <EditStockModal
                    onClose={handleCloseEditModal}
                    product={selectedProduct}
                    onInventoryUpdated={fetchInventories}
                />
            )}
        </div>
    );
};

export default InventoryList;
