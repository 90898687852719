import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {updateNotification, getNotificationById, addNotification} from '../../api/notificationApi';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/pages/admin/EditNotificationPage.css';

const EditNotificationPage: React.FC = () => {
    const { user } = useAuth();
    const { id } = useParams<{ id: string }>();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchNotification(id);
        }
    }, [id]);

    const fetchNotification = async (id: string) => {
        const notification = await getNotificationById(id);
        console.log(notification);

        try {
            setTitle(notification.title);
            setContent(notification.content);
        } catch (error) {
            console.error("Failed to fetch notification", error);
        }
    };

    if (!user?.is_admin) {
        return <div>アクセス権限がありません。</div>;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (id) {
                await updateNotification({ id: Number(id), title, content });
                setMessage('お知らせが更新されました。');
                navigate('/admin'); // 更新後、管理ページに戻る
            } else {
                // 追加処理を実装する場合
            }
        } catch (error) {
            setMessage('更新に失敗しました。');
        }
    };

    return (
        <div className="edit-notification-page">
            <h1>お知らせ編集</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">タイトル</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="content">テキスト</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    ></textarea>
                </div>
                <button type="submit">更新</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default EditNotificationPage;
