import axios from '../utils/axiosSetup';

interface InventoryData {
    ID?: number;
    user_id: number | null;
    share_id: number | null;
    name: string;
    jan: string;
    category: string;
    price: number;
    quantity: number;
    expiration_date: string;
    stock_image?: string;
}

export const getAllInventories = async () => {
    const response = await axios.get('/api/private/inventories');
    return response.data;
};

export const addInventory = async (inventoryData: InventoryData, imageFile: File | null) => {
    const formData = new FormData();

    // JSONの在庫情報を文字列にしてFormDataに追加
    formData.append('inventoryData', JSON.stringify(inventoryData));

    // 画像ファイルがある場合に追加
    if (imageFile) {
        formData.append('stock_image', imageFile);
    }

    try {
        const response = await axios.post('/api/private/inventories', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        if (error.response) {
            console.error("Server responded with an error:", error.response.data);
        } else if (error.request) {
            console.error("No response received from server:", error.request);
        } else {
            console.error("Error setting up the request:", error.message);
        }
        throw error;
    }
};

export const updateInventory = async (inventoryData: InventoryData, imageFile: File | null) => {
    const formData = new FormData();
    console.log(inventoryData);

    formData.append('inventoryData', JSON.stringify(inventoryData));
    if (imageFile) {
        formData.append('stock_image', imageFile);
    }

    try {
        const response = await axios.put(`/api/private/inventories/${inventoryData.ID}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const deleteInventory = async (id: number) => {
    const response = await axios.delete(`/api/private/inventories/${id}`);
    return response.data;
};

export const getAllCategories = async () => {
    const response = await axios.get('/api/private/inventories/allcategories');
    return response.data;
};

export const searchInventories = async (query: string) => {
    const response = await axios.get(`/api/private/inventories/search?query=${query}`);
    return response.data;
};

export const janSearch = async (jan: string) => {
    const response = await axios.get(`/api/private/inventories/jan/${jan}`);
    return response.data;
};

export const increaseQuantity = async (id: number) => {
    const response = await axios.post(`/api/private/inventories/increase/${id}`);
    return response.data;
}

export const decreaseQuantity = async (id: number) => {
    const response = await axios.post(`/api/private/inventories/decrease/${id}`);
    return response.data;
}

export const getInventoryByJan = async (jan: string) => {
    const response = await axios.get(`/api/private/inventories/get/jan/${jan}`);
    return response.data;
}