import React from "react";
import { Link } from "react-router-dom";
import "../../styles/pages/error/Page404.css";

const Page404 = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-message">お探しのページは見つかりませんでした。</p>
                <Link to="/" className="home-link">
                    ホームに戻る
                </Link>
            </div>
        </div>
    );
}

export default Page404;
