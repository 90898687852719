// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    font-family: 'Noto Sans', sans-serif;
}

.not-found-content {
    text-align: center;
    padding: 20px;
    border-radius: 12px;
}

.not-found-title {
    font-size: 72px;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
}

.not-found-message {
    font-size: 24px;
    color: #000;
    font-weight: 300;
    margin-bottom: 20px;
}

.home-link {
    display: inline-block;
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border-radius: 12px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    transition: background 0.3s ease;
}

.home-link:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .not-found-title {
        font-size: 48px;
    }

    .not-found-message {
        font-size: 18px;
    }

    .home-link {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/error/Page404.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".not-found-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #ffffff;\n    font-family: 'Noto Sans', sans-serif;\n}\n\n.not-found-content {\n    text-align: center;\n    padding: 20px;\n    border-radius: 12px;\n}\n\n.not-found-title {\n    font-size: 72px;\n    font-weight: 700;\n    color: #000;\n    margin-bottom: 20px;\n}\n\n.not-found-message {\n    font-size: 24px;\n    color: #000;\n    font-weight: 300;\n    margin-bottom: 20px;\n}\n\n.home-link {\n    display: inline-block;\n    padding: 12px 24px;\n    background-color: #007bff;\n    color: #fff;\n    border-radius: 12px;\n    text-decoration: none;\n    font-size: 16px;\n    font-weight: 700;\n    transition: background 0.3s ease;\n}\n\n.home-link:hover {\n    background-color: #0056b3;\n}\n\n@media (max-width: 768px) {\n    .not-found-title {\n        font-size: 48px;\n    }\n\n    .not-found-message {\n        font-size: 18px;\n    }\n\n    .home-link {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
