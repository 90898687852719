import React from 'react';
import SideBar from "../components/sidebar/SideBar";
import Setting from "../components/settings/Setting";
import '../styles/components/settings/Settings.css';

const SettingsPage = () => {
    return (
        <div className="settings-page">
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}} />
            <Setting />
        </div>
    );
}

export default SettingsPage;
