import React, {useState, useEffect} from 'react';
import '../../styles/components/memo/MemoList.css';
import EditMemoModal from './EditMemoModal';
import AddMemoModal from './AddMemoModal';
import {getAllMemos, addMemo, editMemo, deleteMemo} from '../../api/memoApi';

interface Memo {
    ID: number;
    title: string;
    content: string;
}

const MemoList: React.FC = () => {
    const [memos, setMemos] = useState<Memo[]>([]);
    const [editingMemo, setEditingMemo] = useState<Memo | null>(null);
    const [addingMemo, setAddingMemo] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

    useEffect(() => {
        fetchMemos().then(r => r);
    }, []);

    const fetchMemos = async () => {
        const fetchedMemos = await getAllMemos();
        setMemos(fetchedMemos);
    };

    const handleAddMemo = async (newMemo: { title: string; content: string }) => {
        await addMemo(newMemo);
        await fetchMemos();
        setAddingMemo(false);
    };

    const handleEditMemo = async (editedMemo: Memo) => {
        await editMemo(editedMemo.ID, editedMemo.title, editedMemo.content);
        await fetchMemos();
        setEditingMemo(null);
    };

    const handleDeleteMemo = async (id: number) => {
        await deleteMemo(id);
        await fetchMemos();
    };

    const toggleDropdown = (id: number) => {
        if (id === undefined) {
            return;
        }
        setDropdownOpen(dropdownOpen === id ? null : id);
    };

    return (
        <div className="memo-list-content">
            <div className="memo-list-header">
                <h1>メモリスト</h1>
                <button onClick={() => setAddingMemo(true)} className="add-memo-button">メモを追加</button>
            </div>
            <div className="memo-grid">
                {memos.map((memo) => (
                    <div className="memo-card" key={memo.ID}>
                        <div className="memo-title">{memo.title}</div>
                        <div className="memo-content">{memo.content}</div>
                        <div className="memo-menu">
                            <button className="menu-button" onClick={() => toggleDropdown(memo.ID)}>...</button>
                            {dropdownOpen === memo.ID && (
                                <div className="dropdown-menu">
                                    <button onClick={() => setEditingMemo(memo)}>編集</button>
                                    <button onClick={() => handleDeleteMemo(memo.ID)}>削除</button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {editingMemo && (
                <EditMemoModal
                    memo={editingMemo}
                    onClose={() => setEditingMemo(null)}
                    onSave={handleEditMemo}
                />
            )}

            {addingMemo && (
                <AddMemoModal
                    onClose={() => setAddingMemo(false)}
                    onSave={handleAddMemo}
                />
            )}
        </div>
    );
}

export default MemoList;
