import axios from '../utils/axiosSetup';

export const updateUserData = async (formData: FormData) => {
    try {
        const response = await axios.post('/api/private/user/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
