import React, { useState, useEffect } from 'react';
import '../../styles/components/inventory/AddStockModal.css';
import {addInventory, getInventoryByJan, increaseQuantity} from '../../api/inventoryApi';
import { getUserId } from "../../api/authApi";
import popupAlert from "../../utils/popupAlert";

interface AddStockModalProps {
    onClose: () => void;
    initialData: {
        name?: string;
        janCode?: string;
        price?: number;
        stock_image?: string;
    } | null;
    onInventoryAdded: () => void;
}

const AddStockModal: React.FC<AddStockModalProps> = ({ onClose, initialData, onInventoryAdded }) => {
    const [name, setName] = useState(initialData?.name || '');
    const [category, setCategory] = useState('');
    const [quantity, setQuantity] = useState<number>(1);
    const [janCode, setJanCode] = useState(initialData?.janCode || '');
    const [price, setPrice] = useState<number>(initialData?.price || 0);
    const [expirationDate, setExpirationDate] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [shareId, setShareId] = useState<number | null>(null);

    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getUserId();
            setUserId(id);
        };
        fetchUserId();
    }, []);

    const handleCancel = () => {
        onClose();
    };

    const handleRegister = async () => {
        if (!name || !category || quantity <= 0 || !janCode || price <= 0 || !expirationDate) {
            popupAlert("すべての項目を正しく入力してください", "red");
            return;
        }

        const existsProduct = await getInventoryByJan(janCode);
        if (existsProduct) {
            await increaseQuantity(existsProduct.ID);
            popupAlert("既存の在庫のため、在庫に追加しました", "green");
            onInventoryAdded();
            onClose();
            return;
        }

        try {
            const inventoryData = {
                user_id: userId,
                share_id: shareId,
                name,
                category,
                price,
                quantity,
                expiration_date: new Date(expirationDate).toISOString(),
                jan: janCode,
            };

            // 画像ファイルが設定されていない場合はnullを渡す
            await addInventory(inventoryData, image || null);

            popupAlert("在庫を追加しました", "green");
            onInventoryAdded();
            onClose();
        } catch (error) {
            console.error("在庫登録に失敗しました", error);
            popupAlert("在庫登録に失敗しました", "red");
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>食材追加</h2>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="food-add-container">
                    <div className="food-add-form-wrapper">
                        <div className="food-add-title">食材追加</div>
                        <div className="food-add-input-section">
                            <div className="food-add-input-item">
                                <div className="food-add-label">商品名</div>
                                <div className="food-add-input-field">
                                    <input type="text" placeholder="商品名を入力" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">カテゴリー</div>
                                <div className="food-add-input-field">
                                    <input type="text" placeholder="カテゴリーを入力" value={category} onChange={(e) => setCategory(e.target.value)} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">個数</div>
                                <div className="food-add-input-field">
                                    <input type="text" placeholder="個数を入力" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">JANコード</div>
                                <div className="food-add-input-field">
                                    <input type="text" placeholder="JANコードを入力" value={janCode} onChange={(e) => setJanCode(e.target.value)} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">価格</div>
                                <div className="food-add-input-field">
                                    <input type="text" placeholder="価格を入力" value={price} onChange={(e) => setPrice(Number(e.target.value))} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">賞味期限</div>
                                <div className="food-add-input-field">
                                    <input type="date" placeholder="賞味期限を入力" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">画像</div>
                                <div className="food-add-input-field">
                                    <input type="file" onChange={handleImageChange} accept="image/*" />
                                </div>
                            </div>
                        </div>
                        <div className="food-add-button-section">
                            <button className="food-add-button food-add-cancel" onClick={handleCancel}>キャンセル</button>
                            <button className="food-add-button food-add-register" onClick={handleRegister}>登録</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStockModal;