import React, { useEffect, useState } from 'react';
import { getAllNotifications } from '../../api/notificationApi';
import '../../styles/components/notifications/Notification.css';
import {formatDate} from "../../utils/dateFormer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

interface Notification {
    ID: number;
    title: string;
    content: string;
    CreatedAt: string;
    UpdatedAt: string;
}

const NotificationList = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        const data = await getAllNotifications();
        // 最新の通知が上に表示されるように並び替え
        const sortedData = data.sort((a: Notification, b: Notification) =>
            new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
        );
        setNotifications(sortedData);
    };

    return (
        <div className="main-content">
            <div className="announcement-section">
                <h2>運営からのお知らせ</h2>
                {notifications.map((notification, index) => (
                    <div className="announcement" key={index}>
                        <div className="announcement-text">
                            <h3><FontAwesomeIcon icon={faCircleExclamation}/> {notification.title}</h3>
                            <p style={{whiteSpace: 'pre-line'}}>{notification.content}</p>
                            <br/>
                            <div className="announcement-date">
                                {notification.CreatedAt === notification.UpdatedAt
                                    ? formatDate(notification.CreatedAt)
                                    : `${formatDate(notification.CreatedAt)} (更新: ${formatDate(notification.UpdatedAt)})`}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
