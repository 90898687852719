// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-login-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
}

.line-icon-base {
    display: block;
    width: 100%;
    height: auto;
}

.line-icon-hover,
.line-icon-press {
    display: none;
    width: 100%;
    height: auto;
}

.line-login-button:hover .line-icon-base {
    display: none;
}

.line-login-button:hover .line-icon-hover {
    display: block;
}

.line-login-button:active .line-icon-hover {
    display: none;
}

.line-login-button:active .line-icon-press {
    display: block;
}

.line-login-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/settings/LineLoginButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".line-login-button {\n    display: flex;\n    align-items: center;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    position: relative;\n    padding: 0;\n}\n\n.line-icon-base {\n    display: block;\n    width: 100%;\n    height: auto;\n}\n\n.line-icon-hover,\n.line-icon-press {\n    display: none;\n    width: 100%;\n    height: auto;\n}\n\n.line-login-button:hover .line-icon-base {\n    display: none;\n}\n\n.line-login-button:hover .line-icon-hover {\n    display: block;\n}\n\n.line-login-button:active .line-icon-hover {\n    display: none;\n}\n\n.line-login-button:active .line-icon-press {\n    display: block;\n}\n\n.line-login-text {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n    font-size: 16px;\n    font-weight: 700;\n    white-space: nowrap;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
