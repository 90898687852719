import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../styles/pages/InvitationPage.css';
import { acceptInvitation } from "../api/shareApi";
import popupAlert from "../utils/popupAlert";

const InvitationPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            popupAlert('無効な招待リンクです', "red");
            navigate('/'); // IDが無い場合はホーム画面にリダイレクト
        }
    }, [id, navigate]);

    const handleAccept = async () => {
        if (!id) {
            popupAlert("無効なIDです。もう一度お試しください。", "red");
            return;
        }

        try {
            await acceptInvitation(id);
            popupAlert('招待を承諾しました', "red");
            navigate('/inventory');
        } catch (error: any) {
            console.error('招待承諾中にエラーが発生しました:', error);
            if (error.response && error.response.status === 400) {
                popupAlert('招待を承諾できませんでした。リクエストが無効です。', "red");
            } else if (error.response && error.response.status === 401) {
                popupAlert('認証に失敗しました。再ログインしてください。', "red");
            } else {
                popupAlert('招待を承諾できませんでした。もう一度お試しください。', "red");
            }
        }
    };

    const handleReject = () => {
        popupAlert('招待を拒否しました', "red");
        navigate('/');
    };

    return (
        <div className="invitation-page">
            <div className="invitation-container">
                <div className="invitation-header">
                    <h2>Smart Pantryへの招待</h2>
                    <p>あなたはリストに招待されています。以下のボタンで招待を承諾または拒否してください。</p>
                </div>
                <div className="invitation-buttons">
                    <button className="accept-button" onClick={handleAccept}>承諾</button>
                    <button className="reject-button" onClick={handleReject}>拒否</button>
                </div>
            </div>
        </div>
    );
};

export default InvitationPage;
