// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InvitationPage.css */
.invitation-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
    font-family: 'Arial', sans-serif;
}

.invitation-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.invitation-header h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.invitation-header p {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #666;
}

.invitation-buttons {
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

.accept-button, .reject-button {
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accept-button {
    background-color: #4caf50; /* 承諾ボタンの色 */
    color: white;
}

.accept-button:hover {
    background-color: #45a049;
}

.reject-button {
    background-color: #f44336; /* 拒否ボタンの色 */
    color: white;
}

.reject-button:hover {
    background-color: #e53935;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/InvitationPage.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB,EAAE,YAAY;IACvC,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB,EAAE,YAAY;IACvC,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* InvitationPage.css */\n.invitation-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f4f4f9;\n    font-family: 'Arial', sans-serif;\n}\n\n.invitation-container {\n    background-color: #ffffff;\n    padding: 40px;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    max-width: 500px;\n    width: 100%;\n}\n\n.invitation-header h2 {\n    font-size: 1.8rem;\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.invitation-header p {\n    font-size: 1rem;\n    margin-bottom: 30px;\n    color: #666;\n}\n\n.invitation-buttons {\n    display: flex;\n    justify-content: space-around;\n    gap: 15px;\n}\n\n.accept-button, .reject-button {\n    padding: 12px 20px;\n    font-size: 1rem;\n    border: none;\n    border-radius: 6px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.accept-button {\n    background-color: #4caf50; /* 承諾ボタンの色 */\n    color: white;\n}\n\n.accept-button:hover {\n    background-color: #45a049;\n}\n\n.reject-button {\n    background-color: #f44336; /* 拒否ボタンの色 */\n    color: white;\n}\n\n.reject-button:hover {\n    background-color: #e53935;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
