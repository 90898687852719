import React, { useState } from 'react';
import '../../styles/components/memo/EditMemoModal.css';

interface MemoEditModalProps {
    memo: { ID: number; title: string; content: string };
    onClose: () => void;
    onSave: (memo: { ID: number; title: string; content: string }) => void;
}

const EditMemoModal: React.FC<MemoEditModalProps> = ({ memo, onClose, onSave }) => {
    const [editedMemo, setEditedMemo] = useState(memo);
    const [error, setError] = useState<{ title: boolean, content: boolean }>({ title: false, content: false });

    const handleSave = () => {
        if (!editedMemo.title || !editedMemo.content) {
            setError({
                title: !editedMemo.title,
                content: !editedMemo.content,
            });
            return;
        }
        onSave(editedMemo);
        onClose(); // モーダルを閉じる
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const handleChange = (field: 'title' | 'content', value: string) => {
        setEditedMemo({ ...editedMemo, [field]: value });
        setError({ ...error, [field]: false });
    };

    return (
        <div className="modal" onClick={handleClickOutside}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>メモを編集</h2>
                <div className="modal-body">
                    <label>タイトル</label>
                    <input
                        type="text"
                        value={editedMemo.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                        required
                    />
                    {error.title && <span className="error">タイトルは必須です。</span>}
                    <label>内容</label>
                    <textarea
                        value={editedMemo.content}
                        onChange={(e) => handleChange('content', e.target.value)}
                        required
                    />
                    {error.content && <span className="error">内容は必須です。</span>}
                </div>
                <div className="modal-footer">
                    <button className="cancel-button" onClick={onClose}>キャンセル</button>
                    <button className="save-button" onClick={handleSave}>保存</button>
                </div>
            </div>
        </div>
    );
};

export default EditMemoModal;
