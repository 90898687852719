export const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Asia/Tokyo'
    };
    return new Date(dateString).toLocaleString('ja-JP', options);
};

export const formatDay = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: false,
        timeZone: 'Asia/Tokyo'
    };
    return new Date(dateString).toLocaleString('ja-JP', options);
};