// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unique-memo-page {
    display: flex;
    height: 100vh; /* サイドバーとメインコンテンツを画面全体に合わせる */
    width: 100vw;
}

.memo-list-container {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px;
    transition: margin-left 0.3s ease;
    margin-left: 250px; /* サイドバーの幅を考慮 */
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .memo-list-container {
        margin-left: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/MemoPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa,EAAE,6BAA6B;IAC5C,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;IACb,iCAAiC;IACjC,kBAAkB,EAAE,eAAe;IACnC,sBAAsB;AAC1B;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".unique-memo-page {\n    display: flex;\n    height: 100vh; /* サイドバーとメインコンテンツを画面全体に合わせる */\n    width: 100vw;\n}\n\n.memo-list-container {\n    flex-grow: 1;\n    overflow-y: auto;\n    background-color: #fff;\n    padding: 20px;\n    transition: margin-left 0.3s ease;\n    margin-left: 250px; /* サイドバーの幅を考慮 */\n    box-sizing: border-box;\n}\n\n@media (max-width: 768px) {\n    .memo-list-container {\n        margin-left: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
