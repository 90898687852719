import React from 'react';
import SideBar from '../components/sidebar/SideBar';
import MemoList from '../components/memo/MemoList';
import '../styles/pages/MemoPage.css';

const MemoPage: React.FC = () => {
    return (
        <div className="unique-memo-page">
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}}/>
            <div className="memo-list-container">
                <MemoList />
            </div>
        </div>
    );
};

export default MemoPage;
