import axios from '../utils/axiosSetup';

export const register = async (username: string, email: string, password: string) => {
    const response = await axios.post('/api/public/register', { username, email, password });
    return response.data;
};

export const login = async (email: string, password: string) => {
    const response = await axios.post('/api/public/login', { email, password });
    return response.data;
};

export const getCurrentUser = async () => {
    const response = await axios.get('/api/private/user');
    return response.data;
};

export const logout = async () => {
    const response = await axios.post('/api/private/logout', {});
    return response.data;
};

export const getUserId = async () => {
    const response = await axios.get('/api/private/user_id');
    return response.data.user_id;
};

export const getShareId = async () => {
    const response = await axios.get(`/api/private/user/share_id`);
    return response.data.share_id;
};