import axios from '../utils/axiosSetup';

export const getAllMemos = async () => {
    const response = await axios.get('/api/private/memos');
    return response.data;
};

export const addMemo = async (memo: { title: string, content: string }) => {
    const response = await axios.post('/api/private/memos', { ...memo });
    return response.data;
};

export const deleteMemo = async (id: number) => {
    const response = await axios.delete(`/api/private/memos/${id}`);
    return response.data;
};

export const editMemo = async (id: number, title: string, content: string) => {
    const response = await axios.put(`/api/private/memos/${id}`, { title, content });
    return response.data;
};
