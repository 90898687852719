import React from "react";
import "../styles/pages/TermsOfService.css";

const TermsOfService: React.FC = () => {
    return (
        <div className="tos-container">
            <h1>利用規約</h1>
            <section>
                <h2>1. はじめに</h2>
                <p>
                    この利用規約（以下、「本規約」といいます。）は、Smart Pantry（以下、「当社」といいます。）が提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。ユーザーは本規約に同意した上で、本サービスを利用するものとします。
                </p>
            </section>
            <section>
                <h2>2. アカウント登録</h2>
                <p>
                    本サービスの利用にはアカウントの登録が必要です。ユーザーは、登録時に提供する情報が正確であることを保証するものとします。
                </p>
            </section>
            <section>
                <h2>3. 禁止事項</h2>
                <ul>
                    <li>本サービスを不正に利用する行為</li>
                    <li>他のユーザーに不利益または損害を与える行為</li>
                    <li>法律に違反する行為</li>
                </ul>
            </section>
            <section>
                <h2>4. 責任の限定</h2>
                <p>
                    当社は、ユーザーによる本サービスの利用に関して生じた損害について、故意または重過失がない限り一切の責任を負いません。
                </p>
            </section>
            <section>
                <h2>5. 変更および終了</h2>
                <p>
                    当社は、事前の通知なく本サービスの内容を変更または終了することがあります。
                </p>
            </section>
            <section>
                <h2>6. 準拠法および裁判管轄</h2>
                <p>
                    本規約は、日本法に準拠し、日本の裁判所を専属的な管轄裁判所とします。
                </p>
            </section>
        </div>
    );
};

export default TermsOfService;
