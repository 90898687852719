import React, { useState } from 'react';
import '../../styles/components/inventory/SearchStockModal.css';
import { searchInventories } from "../../api/inventoryApi";

interface SearchStockModalProps {
    onClose: (searchQuery?: string, results?: any) => void;
}

const SearchStockModal = ({ onClose }: SearchStockModalProps) => {
    const [searchValue, setSearchValue] = useState("");

    const handleCancel = () => {
        onClose();
    };

    const handleSearch = async () => {
        const results = await searchInventories(searchValue);
        onClose(searchValue, results); // 検索後にモーダルを閉じて検索結果を渡す
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // モーダルの背景をクリックしたときのみ onClose を呼び出す
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleModalClick}>
            <div className="modal-content">
                <h1>食材検索</h1>
                <label htmlFor="search-input">食材・調味料名</label>
                <input
                    type="text"
                    id="search-input"
                    className="search-input"
                    value={searchValue}
                    onChange={handleInputChange}
                />
                <div className="button-container">
                    <button className="cancel-button" onClick={handleCancel}>キャンセル</button>
                    <button className="search-button" onClick={handleSearch}>検索</button>
                </div>
            </div>
        </div>
    );
};

export default SearchStockModal;
