import React, { useRef, useState, useEffect, useCallback } from 'react';
import Webcam from 'react-webcam';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import '../../styles/components/barcode/BarcodeScannerModal.css';
import { getInventoryByJan, increaseQuantity, janSearch } from "../../api/inventoryApi";
import AddStockModal from '../inventory/AddStockModal';
import { FaSyncAlt } from 'react-icons/fa'; // 切り替えアイコン用

interface BarcodeModalProps {
    onClose: () => void;
    onAdd: (product: any) => void;
    onInventoryAdded: () => void;
}

interface Product {
    name: string;
    price: number;
    janCode: string;
}

const BarcodeModal: React.FC<BarcodeModalProps> = ({ onClose, onAdd, onInventoryAdded }) => {
    const webcamRef = useRef<Webcam>(null);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(true);
    const [productData, setProductData] = useState<Product | null>(null);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment'); // カメラの向き

    // カメラの向きを設定するための videoConstraints を定義
    const videoConstraints = {
        facingMode: facingMode // 'user' (内カメラ) または 'environment' (外カメラ)
    };

    const capture = useCallback(async () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                try {
                    const result = await codeReader.decodeFromImage(undefined, imageSrc);
                    const code = result.getText();
                    console.log('Barcode detected:', code);

                    const response = await janSearch(code);
                    console.log('Product data:', response);

                    const existsProduct = await getInventoryByJan(code);
                    if (existsProduct) {
                        await increaseQuantity(existsProduct.ID);
                        onClose();
                        return;
                    }

                    setProductData(response);
                    setScanning(false);
                } catch (err) {
                    if (err instanceof NotFoundException) {
                        console.log('No barcode detected');
                    } else {
                        console.error(err);
                    }
                }
            }
        }
    }, [codeReader, onInventoryAdded, onClose]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (scanning) {
                capture();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [capture, scanning]);

    // カメラの向きを切り替える関数
    const handleCameraToggle = () => {
        setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
    };

    return (
        <div className="modal-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div className="modal-content">
                {scanning ? (
                    <>
                        <span className="close" onClick={onClose}>&times;</span>
                        <h2>バーコードを追加</h2>
                        <div className="webcam-container">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints} // 修正した videoConstraints を適用
                                className="webcam"
                            />
                            <button className="toggle-camera-button" onClick={handleCameraToggle}>
                                <FaSyncAlt size={20}/>
                                カメラ切り替え
                            </button>
                        </div>
                    </>
                ) : (
                    <AddStockModal
                        onClose={onClose}
                        initialData={productData}
                        onInventoryAdded={onInventoryAdded}
                    />
                )}
            </div>
        </div>
    );
};

export default BarcodeModal;
