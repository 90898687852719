import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUser } from "../../api/authApi";
import popupAlert from "../../utils/popupAlert";

function CallbackPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const code = new URLSearchParams(location.search).get("code");
            if (code) {
                try {
                    const result = await axios.post('/api/public/google-login', { code });
                    if (result.data && result.data.token) {
                        const userResult = await getCurrentUser();
                        if (userResult && userResult.data) {
                            setUser(userResult.data);
                            navigate('/welcome');
                        } else {
                            popupAlert("Failed to fetch user data.", "red");
                            navigate('/login');
                        }
                    } else {
                        alert("Google login failed.");
                        navigate('/login');
                    }
                } catch (error) {
                    console.error("Google login error", error);
                    alert("Google login error");
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        fetchData();
    }, [location.search, navigate, setUser]);

    return (
        <div className="callback-container">
            <div>Loading...</div>
        </div>
    );
}

export default CallbackPage;
