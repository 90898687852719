import React from "react";
import "../styles/pages/PrivacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="pp-container">
            <h1>プライバシーポリシー</h1>
            <section>
                <h2>1. 個人情報の収集について</h2>
                <p>
                    当社は、本サービスの提供に必要な範囲で、ユーザーの個人情報（氏名、メールアドレスなど）を収集します。
                </p>
            </section>
            <section>
                <h2>2. 個人情報の利用目的</h2>
                <p>
                    収集した個人情報は、以下の目的のために利用します。
                    <ul>
                        <li>本サービスの提供・運営</li>
                        <li>ユーザーへの連絡・通知</li>
                        <li>本サービスの改善</li>
                    </ul>
                </p>
            </section>
            <section>
                <h2>3. 個人情報の第三者提供</h2>
                <p>
                    当社は、法令に基づく場合を除き、ユーザーの同意なく個人情報を第三者に提供することはありません。
                </p>
            </section>
            <section>
                <h2>4. セキュリティ対策</h2>
                <p>
                    当社は、ユーザーの個人情報を安全に管理するための適切なセキュリティ対策を講じます。
                </p>
            </section>
            <section>
                <h2>5. 個人情報の開示・訂正・削除</h2>
                <p>
                    ユーザーは、自身の個人情報の開示、訂正、削除を希望する場合、当社に問い合わせることができます。
                </p>
            </section>
            <section>
                <h2>6. お問い合わせ</h2>
                <p>
                    プライバシーポリシーに関するお問い合わせは、<a href="mailto:support@smartpantry.jp">support@smartpantry.jp</a>までご連絡ください。
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
