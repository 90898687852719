import SideBar from "../components/sidebar/SideBar";
import WelcomeComponent from "../components/WelcomeComponent";

const WelcomePage = () => {
    return (
        <div>
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}}/>
            <WelcomeComponent />
        </div>
    );
}
export default WelcomePage;