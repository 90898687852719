import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {getAllNotifications, addNotification, deleteNotification} from '../../api/notificationApi';
import '../../styles/pages/admin/AdminPage.css';
import popupAlert from "../../utils/popupAlert";

interface Notification {
    ID: number;
    title: string;
    content: string;
    date: string;
}

const AdminPage: React.FC = () => {
    const { user } = useAuth();
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        const data = await getAllNotifications();
        setNotifications(data);
    };

    const handleEditClick = (id: number) => {
        navigate(`/admin/notification/${id}`);
    };

    const handleDeleteClick = async (id: number) => {
        const confirmDelete = window.confirm("本当にこのお知らせを削除しますか？");
        if (confirmDelete) {
            await deleteNotification(id);
            fetchNotifications();
        }
    }

    const handleAddNotification = async () => {
        if (title.trim() === '' || content.trim() === '') {
            popupAlert('タイトルとコンテンツを入力してください', "red");
            return;
        }

        console.log(title,content)

        const newNotification = await addNotification(title, content);
        setNotifications([...notifications, newNotification]);
        setTitle('');
        setContent('');
        fetchNotifications()
    };

    if (!user?.is_admin) {
        console.log(user)
        return <div>アクセス権限がありません。</div>;
    }

    return (
        <div className="admin-page">
            <h1>お知らせ一覧</h1>
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>タイトル</th>
                    <th>内容</th>
                    <th>編集</th>
                    <th>削除</th>
                </tr>
                </thead>
                <tbody>
                {notifications.map((notification) => (
                    //console.log(notification),
                    <tr key={notification.ID}>
                        <td>{notification.ID}</td>
                        <td>{notification.title}</td>
                        <td>{notification.content}</td>
                        <td>
                            <button onClick={() => handleEditClick(notification.ID)}>編集</button>
                        </td>
                        <td>
                            <button onClick={() => handleDeleteClick(notification.ID)}>削除</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="add-notification">
                <h2>新しいお知らせを追加</h2>
                <input
                    type="text"
                    placeholder="タイトル"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                    placeholder="コンテンツ"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <button onClick={handleAddNotification}>追加</button>
            </div>
        </div>
    );
};

export default AdminPage;
