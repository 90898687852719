import React, {useEffect, useState} from 'react';
import '../../styles/components/share/ShareModal.css';
import {useNavigate} from 'react-router-dom';
import {generateInviteLink, getAllSharedUsers, sendInvitationEmail, leaveShare, removeShare} from "../../api/shareApi";
import {getUserId, getShareId} from "../../api/authApi";
import popupAlert from "../../utils/popupAlert";

interface Member {
    name: string;
    email: string;
    profile_pic: string;
    id: number;
}

const ShareModal: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [members, setMembers] = useState<Member[]>([]);
    const [userId, setUserId] = useState<number | null>(null);
    const [shareId, setShareId] = useState<number | null>(null);
    const [showEmailPopup, setShowEmailPopup] = useState<boolean>(false);
    const [inviteEmail, setInviteEmail] = useState<string>('');

    // ユーザーIDと共有IDの取得
    useEffect(() => {
        getUserId().then(id => setUserId(id)).catch(error => console.error("ユーザーIDの取得に失敗しました:", error));
        getShareId().then(id => setShareId(id)).catch(error => console.error("共有IDの取得に失敗しました:", error));
    }, []);

    // 共有ユーザーリストの取得
    useEffect(() => {
        if (shareId !== null) {
            getAllSharedUsers().then(response => {
                if (response) {
                    const userList = response.users.map((user: any) => ({
                        name: user.username,
                        email: user.email,
                        profile_pic: user.profile_pic, // 確実にprofile_picを取得
                        id: user.id
                    }));

                    // 自分のユーザーを先頭に移動
                    moveCurrentUserToTop(userList, userId);
                    setMembers(userList);
                }
            }).catch(error => console.error("共有ユーザーの取得に失敗しました:", error));
        }
    }, [userId, shareId]); // userIdとshareIdに依存

    const moveCurrentUserToTop = (userList: any[], userId: number | null) => {
        if (userId !== null) {
            const currentUserIndex = userList.findIndex((user: { id: number; }) => user.id === userId);
            if (currentUserIndex !== -1) {
                const [currentUser] = userList.splice(currentUserIndex, 1);
                userList.unshift(currentUser);
            }
        }
    };

    const handleGenerateInviteLinkClick = async () => {
        try {
            const link = await generateInviteLink();
            if (link) {
                await navigator.clipboard.writeText(link);
                popupAlert('招待リンクをコピーしました', "green");

                // 共有IDが生成された場合、shareIdを再設定
                const newShareId = await getShareId();
                setShareId(newShareId); // これによりユーザーリストが再取得される
            }
        } catch (error) {
            console.error('招待リンクの生成またはコピーに失敗しました:', error);
        }
    };

    const handleRemove = (member: any) => {
        if (member.email === members[0].email) {
            leaveShare().then(() => {
                navigate('/inventory');
            }).catch(error => console.error("共有の退会に失敗しました:", error));
        } else {
            removeShare(member.email).then(() => {
                setMembers(members.filter(m => m.email !== member.email));
            }).catch(error => console.error("共有の削除に失敗しました:", error));
        }
    };

    const onClose = () => {
        navigate('/inventory');
    };

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const handleEmailInviteClick = () => {
        setShowEmailPopup(true);
    };

    const handleSendEmail = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inviteEmail || !emailRegex.test(inviteEmail)) {
            popupAlert('有効なメールアドレスを入力してください', "red");
            return;
        }

        if (inviteEmail) {
            await sendInvitationEmail(inviteEmail)
                .then((response) => {
                    console.log(response.message);
                    setShowEmailPopup(false); // ポップアップを閉じる
                    setInviteEmail(''); // メール入力欄をリセット
                    // アラートを表示
                    popupAlert('招待メールを送信しました', "green");
                })
                .catch((error) => {
                    console.error('招待メールの送信に失敗しました:', error);
                });
        }
    };

    return (
        <div className="modal-overlay" onClick={handleModalClick}>
            <div className="modal-content">
                <button onClick={onClose} className="invite-modal-close-button">×</button>
                <div className="invite-modal-header">
                    <div className="invite-modal-icon">
                        <span className="invite-modal-icon-circle">
                            <span className="invite-modal-icon-plus">+</span>
                        </span>
                    </div>
                    <h2 className="invite-modal-title">リストに招待する</h2>
                </div>
                <div className="invite-modal-search-bar">
                    <span className="invite-modal-search-icon">🔍</span>
                    <input
                        className="invite-modal-search-input"
                        type="text"
                        placeholder="メンバーをリストから検索する..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="invite-modal-member-list">
                    {shareId === null ? (
                        <div className="invite-modal-no-share">
                            <p>共有をしていません、共有を開始しますか？</p>
                            <p>共有を開始するには、URLで招待か、メールで招待を選択してください。</p>
                        </div>
                    ) : (
                        members
                            .filter(member =>
                                member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                member.email.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((member, index) => (
                                <div key={index} className="invite-modal-member-item">
                                    <div className="invite-modal-member-info">
                                        <img
                                            className="invite-modal-member-avatar"
                                            src={member.profile_pic}
                                            alt="プロフィール画像"
                                        />
                                        <div>
                                            <div className="invite-modal-member-name">{member.name}</div>
                                            <div className="invite-modal-member-email">{member.email}</div>
                                        </div>
                                    </div>
                                    <button
                                        className="invite-modal-action-button"
                                        onClick={() => handleRemove(member)}
                                    >
                                        {index === 0 ? '退会' : '削除'}
                                    </button>
                                </div>
                            ))
                    )}
                </div>
                <div className="invite-modal-invite-actions">
                    <button className="invite-modal-invite-button" onClick={handleGenerateInviteLinkClick}>
                        <span className="invite-modal-invite-button-icon">🔗</span> URLで招待
                    </button>
                    <button className="invite-modal-invite-button" onClick={handleEmailInviteClick}>
                        <span className="invite-modal-invite-button-icon">✉️</span> メールで招待
                    </button>
                </div>
            </div>

            {showEmailPopup && (
                <div className="email-popup">
                    <div className="email-popup-content">
                        <h3>招待したいユーザーのメールアドレスを入力してください</h3>
                        <input
                            type="email"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                            placeholder="example@example.com"
                        />
                        <button onClick={handleSendEmail}>送信</button>
                        <button onClick={() => setShowEmailPopup(false)}>キャンセル</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShareModal;
