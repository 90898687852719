import React from 'react';
import '../../styles/components/settings/LineLoginButton.css';
import LineLoginBase from '../../assets/images/LINE/btn_login_base.png';
import LineLoginHover from '../../assets/images/LINE/btn_login_hover.png';
import LineLoginPress from '../../assets/images/LINE/btn_login_press.png';

interface LineLoginButtonProps {
    onClick: () => void;
}

const LineLoginButton: React.FC<LineLoginButtonProps> = ({ onClick }) => {
    return (
        <button className="line-login-button" onClick={onClick}>
            <img src={LineLoginBase} alt="LINE" className="line-icon-base" />
            <img src={LineLoginHover} alt="LINE" className="line-icon-hover" />
            <img src={LineLoginPress} alt="LINE" className="line-icon-press" />
        </button>
    );
};

export default LineLoginButton;
