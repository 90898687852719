import React, { useState } from 'react';
import SideBar from '../components/sidebar/SideBar';
import InventoryList from '../components/inventory/InventoryList';
import BarcodeModal from '../components/barcode/BarcodeScannerModal';
import SearchStockModal from '../components/inventory/SearchStockModal';

const InventoryPage: React.FC = () => {
    const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleOpenBarcodeModal = () => {
        setIsBarcodeModalOpen(true);
    };

    const handleCloseBarcodeModal = () => {
        setIsBarcodeModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = (searchQuery?: string, results?: any) => {
        setIsModalOpen(false);
        if (results) {
            setSearchResults(results);
        }
    };

    const handleAddProduct = (product: { id: number; name: string; quantity: number }) => {
        // 在庫一覧に商品を追加する処理をここに実装します
        console.log('商品が追加されました:', product);
    };

    const handleInventoryAdded = () => {
        // 在庫が追加された後の処理 (例: 在庫リストの再取得)
        console.log("Inventory added, refreshing the list...");
        // 在庫リストの再取得のための関数をここで呼び出す
    };

    return (
        <div className="unique-inventory-page">
            <SideBar onSearchClick={handleOpenModal} onBarcodeClick={handleOpenBarcodeModal} />
            <InventoryList searchResults={searchResults} />
            {isBarcodeModalOpen && (
                <BarcodeModal onClose={handleCloseBarcodeModal} onAdd={handleAddProduct} onInventoryAdded={handleInventoryAdded} />
            )}
            {isModalOpen && <SearchStockModal onClose={handleCloseModal} />}
        </div>
    );
};

export default InventoryPage;
