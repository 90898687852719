// Welcome.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/pages/Welcome.css";
import { getAllInventories } from "../api/inventoryApi"; // 仮のAPI呼び出し関数

function WelcomeComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkInventory = async () => {
            try {
                const inventories = await getAllInventories(); // 仮のAPI呼び出し
                if (inventories.length > 0) {
                    navigate('/inventory'); // 在庫ページにリダイレクト
                }
            } catch (error) {
                console.error("在庫がありません。:", error);
            }
        };

        checkInventory();
    }, [navigate]);

    return (
        <div className="welcome">
            <div className="container">
                <div className="content">
                    <div className="title">
                        <span>Smart Pantry</span> へようこそ！
                    </div>
                    <div className="subtitle">まずは、在庫の登録を行いましょう！</div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeComponent;
