import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopPage from "./pages/TopPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import WelcomePage from "./pages/WelcomePage";
import NotificationPage from "./pages/NotificationPage";
import SearchPage from "./pages/SearchPage";
import SettingsPage from "./pages/SettingsPage";
import InventoryPage from "./pages/InventoryPage";
import SharingPage from "./pages/SharingPage";
import MemoPage from "./pages/MemoPage";
import Page404 from "./pages/error/Page404";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import CallbackPage from "./pages/callback/CallbackPage";
import AdminPage from "./pages/admin/AdminPage";
import EditNotificationPage from "./pages/admin/EditNotificationPage";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import InvitationPage from "./pages/InvitationPage";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

function App() {
    return (

            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<TopPage />} />
                        <Route path="/terms" element={<TermsOfService />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/welcome" element={<PrivateRoute element={WelcomePage} />} />
                        <Route path="/notifications" element={<PrivateRoute element={NotificationPage} />} />
                        <Route path="/search" element={<PrivateRoute element={SearchPage} />} />
                        <Route path="/settings" element={<PrivateRoute element={SettingsPage} />} />
                        <Route path="/inventory" element={<PrivateRoute element={InventoryPage} />} />
                        <Route path="/sharing" element={<PrivateRoute element={SharingPage} />} />
                        <Route path="/shopping" element={<PrivateRoute element={MemoPage} />} />
                        <Route path="/callback" element={<CallbackPage />} />
                        <Route path="/admin" element={<PrivateRoute element={AdminPage} />} /> {/* 管理ページへのルート */}
                        <Route path="/admin/notification/:id" element={<PrivateRoute element={EditNotificationPage} />} /> {/* 編集ページへのルート */}
                        <Route path="/sharing/invitation/:id" element={<PrivateRoute element={InvitationPage} />} /> {/* 招待ページへのルート */}
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
    );
}

export default App;
