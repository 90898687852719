import React from 'react';
import SideBar from '../components/sidebar/SideBar';
import NotificationList from '../components/notifications/NotificationList';

const NotificationPage = () => {
    return (
        <div className="unique-notification-page">
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}}/>
            <NotificationList/>
        </div>
    );
};

export default NotificationPage;
