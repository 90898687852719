// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-alert {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4caf50; /* 緑色の背景（成功を示す） */
    color: #fff;
    padding: 15px 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: bold;
    z-index: 4000; /* モーダルよりも前面に表示されるように設定 */
    animation: fadein 0.5s, fadeout 0.5s 2.5s; /* フェードイン/アウトのアニメーション */
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}
`, "",{"version":3,"sources":["webpack://./src/styles/utils/popupAlert.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB,EAAE,iBAAiB;IAC5C,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,0CAA0C;IAC1C,eAAe;IACf,iBAAiB;IACjB,aAAa,EAAE,yBAAyB;IACxC,yCAAyC,EAAE,uBAAuB;AACtE;;AAEA;IACI,OAAO,UAAU,EAAE;IACnB,KAAK,UAAU,EAAE;AACrB;;AAEA;IACI,OAAO,UAAU,EAAE;IACnB,KAAK,UAAU,EAAE;AACrB","sourcesContent":[".custom-alert {\n    position: fixed;\n    top: 20%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #4caf50; /* 緑色の背景（成功を示す） */\n    color: #fff;\n    padding: 15px 30px;\n    border-radius: 8px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    font-size: 16px;\n    font-weight: bold;\n    z-index: 4000; /* モーダルよりも前面に表示されるように設定 */\n    animation: fadein 0.5s, fadeout 0.5s 2.5s; /* フェードイン/アウトのアニメーション */\n}\n\n@keyframes fadein {\n    from { opacity: 0; }\n    to { opacity: 1; }\n}\n\n@keyframes fadeout {\n    from { opacity: 1; }\n    to { opacity: 0; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
