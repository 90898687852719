// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Smoothのみ html に適用 */
html {
    scroll-behavior: smooth;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,uBAAuB;IACvB,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":["/* Smoothのみ html に適用 */\nhtml {\n    scroll-behavior: smooth;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
