import React, { useState } from 'react';
import '../../styles/components/inventory/AddStockModal.css';
import { updateInventory } from '../../api/inventoryApi';
import popupAlert from "../../utils/popupAlert";

interface InventoryData {
    ID: number;
    user_id: number | null;
    share_id: number | null;
    name: string;
    jan: string;
    category: string;
    price: number;
    quantity: number;
    expiration_date: string;
    stock_image?: string;
}

interface EditStockModalProps {
    onClose: () => void;
    product: InventoryData; // 編集対象の商品
    onInventoryUpdated: () => void;
}

const EditStockModal: React.FC<EditStockModalProps> = ({ onClose, product, onInventoryUpdated }) => {
    const [name, setName] = useState(product.name);
    const [category, setCategory] = useState(product.category);
    const [quantity, setQuantity] = useState<number>(product.quantity);
    const [janCode, setJanCode] = useState(product.jan);
    const [price, setPrice] = useState<number>(product.price);
    const [expirationDate, setExpirationDate] = useState<string>(
        product.expiration_date
            ? new Date(product.expiration_date).toISOString().split('T')[0]
            : ''
    );
    const [image, setImage] = useState<File | null>(null);

    const handleSave = async () => {
        if (!name || !category || quantity <= 0 || !janCode || price <= 0 || !expirationDate) {
            popupAlert("すべての項目を正しく入力してください", "red");
            return;
        }

        try {
            const inventoryData = {
                ID: product.ID,
                user_id: product.user_id,
                share_id: product.share_id,
                name,
                jan: janCode,
                category,
                price,
                quantity,
                expiration_date: new Date(expirationDate).toISOString(),
            };

            await updateInventory(inventoryData, image || null);

            popupAlert("在庫を更新しました", "green");
            onInventoryUpdated();
            onClose();
        } catch (error) {
            console.error("在庫の更新に失敗しました", error);
            popupAlert("在庫の更新に失敗しました", "red");
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>在庫編集</h2>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="food-add-container">
                    <div className="food-add-form-wrapper">
                        <div className="food-add-title">在庫編集</div>
                        <div className="food-add-input-section">
                            <div className="food-add-input-item">
                                <div className="food-add-label">商品名</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="text"
                                        placeholder="商品名を入力"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">カテゴリー</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="text"
                                        placeholder="カテゴリーを入力"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">個数</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="number"
                                        placeholder="個数を入力"
                                        value={quantity}
                                        onChange={(e) => setQuantity(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">JANコード</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="text"
                                        placeholder="JANコードを入力"
                                        value={janCode}
                                        onChange={(e) => setJanCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">価格</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="number"
                                        placeholder="価格を入力"
                                        value={price}
                                        onChange={(e) => setPrice(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">賞味期限</div>
                                <div className="food-add-input-field">
                                    <input
                                        type="date"
                                        placeholder="賞味期限を入力"
                                        value={expirationDate}
                                        onChange={(e) => setExpirationDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="food-add-input-item">
                                <div className="food-add-label">画像</div>
                                <div className="food-add-input-field">
                                    <input type="file" onChange={handleImageChange} accept="image/*" />
                                </div>
                            </div>
                        </div>
                        <div className="food-add-button-section">
                            <button className="food-add-button food-add-cancel" onClick={onClose}>
                                キャンセル
                            </button>
                            <button className="food-add-button food-add-register" onClick={handleSave}>
                                保存
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditStockModal;
