// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-notification-page {
    margin: 20px;
}

.edit-notification-page h1 {
    margin-bottom: 20px;
}

.edit-notification-page form {
    display: flex;
    flex-direction: column;
}

.edit-notification-page .form-group {
    margin-bottom: 15px;
}

.edit-notification-page .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.edit-notification-page .form-group input,
.edit-notification-page .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.edit-notification-page .form-group textarea {
    height: 150px;
    resize: vertical;
}

.edit-notification-page button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    align-self: flex-start;
}

.edit-notification-page button:hover {
    background-color: #0056b3;
}

.edit-notification-page p {
    margin-top: 20px;
    color: green;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/admin/EditNotificationPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".edit-notification-page {\n    margin: 20px;\n}\n\n.edit-notification-page h1 {\n    margin-bottom: 20px;\n}\n\n.edit-notification-page form {\n    display: flex;\n    flex-direction: column;\n}\n\n.edit-notification-page .form-group {\n    margin-bottom: 15px;\n}\n\n.edit-notification-page .form-group label {\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.edit-notification-page .form-group input,\n.edit-notification-page .form-group textarea {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-sizing: border-box;\n}\n\n.edit-notification-page .form-group textarea {\n    height: 150px;\n    resize: vertical;\n}\n\n.edit-notification-page button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    border-radius: 4px;\n    align-self: flex-start;\n}\n\n.edit-notification-page button:hover {\n    background-color: #0056b3;\n}\n\n.edit-notification-page p {\n    margin-top: 20px;\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
