import React, {useState} from "react";
import "../styles/pages/Login.css";
import sendIcon from "../assets/images/icons/mdi_send.png";
import {register, login, getCurrentUser} from '../api/authApi';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({username: '', email: '', password: '', general: ''}); // エラーメッセージを保持する
    const navigate = useNavigate();
    const {setUser} = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors = {username: '', email: '', password: '', general: ''};

        // フロントエンドバリデーション
        if (!email.includes('@')) {
            newErrors.email = 'メールアドレスが正しくありません';
        }
        if (!username) {
            newErrors.username = 'ユーザー名を入力してください';
        }

        if (process.env.REACT_APP_ENV === 'production') {
            if (password.length < 6) {
                newErrors.password = 'パスワードは6文字以上である必要があります';
            }
        }

        setErrors(newErrors);

        if (newErrors.email || newErrors.username || newErrors.password) {
            return; // エラーがある場合は送信を中断
        }

        try {
            const result = await register(username, email, password);
            if (result) {
                const loginResult = await login(email, password);
                if (loginResult.token) {
                    const userResult = await getCurrentUser();
                    if (userResult && userResult.data) {
                        setUser(userResult.data);
                        navigate('/welcome');
                    } else {
                        setErrors({...newErrors, general: "ユーザーデータの取得に失敗しました。"});
                    }
                } else {
                    setErrors({...newErrors, general: "登録後のログインに失敗しました。"});
                }
            } else {
                setErrors({...newErrors, general: result.error});
            }
        } catch (error) {
            console.error("登録エラー", error);
            setErrors({...newErrors, general: "登録エラーが発生しました。"});
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-title">Smart Pantry</div>
                    <div className="form-subtitle">メールアドレスで新規登録</div>

                    {/* 全般的なエラーメッセージ */}
                    {errors.general && <div className="error-text general-error">{errors.general}</div>}

                    {/* メールアドレスフィールド */}
                    <label className="form-label" htmlFor="email">メールアドレス</label>
                    {errors.email && <div className="error-text">{errors.email}</div>}
                    <input
                        className={`form-input ${errors.email ? 'input-error' : ''}`}
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@example.com"
                        required
                    />

                    {/* ユーザー名フィールド */}
                    <label className="form-label" htmlFor="name">名前</label>
                    {errors.username && <div className="error-text">{errors.username}</div>}
                    <input
                        className={`form-input ${errors.username ? 'input-error' : ''}`}
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="山田太郎"
                        required
                    />

                    {/* パスワードフィールド */}
                    <label className="form-label" htmlFor="password">パスワード</label>
                    {errors.password && <div className="error-text">{errors.password}</div>}
                    <input
                        className={`form-input ${errors.password ? 'input-error' : ''}`}
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />

                    <button className="submit-button" type="submit">
                        <img
                            loading="lazy"
                            src={sendIcon}
                            className="button-icon"
                            alt="Submit Icon"
                        />
                        <span className="button-text">送信</span>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default RegisterPage;
