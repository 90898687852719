import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from '../components/sidebar/SideBar';
import SearchStockModal from '../components/inventory/SearchStockModal';

const SearchPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const navigate = useNavigate();

    const handleCloseModal = (searchQuery?: string) => {
        if (searchQuery) {
            navigate(`/inventory?q=${searchQuery}`);
        } else {
            navigate('/inventory');
        }
        setIsModalOpen(false);
    };

    return (
        <div>
            <SideBar onBarcodeClick={() => {}} onSearchClick={() => {}}/>
            {isModalOpen && <SearchStockModal onClose={handleCloseModal} />}
        </div>
    );
};

export default SearchPage;
