import React, {useState} from "react";
import "../styles/pages/Login.css";
import sendIcon from "../assets/images/icons/mdi_send.png";
import {login, getCurrentUser} from '../api/authApi';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({email: '', password: '', general: ''}); // General error for other cases
    const navigate = useNavigate();
    const {setUser} = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors = {email: '', password: '', general: ''};

        // フロントエンドでの簡単なバリデーション
        if (!email.includes('@')) {
            newErrors.email = 'メールアドレスが正しくありません';
        }

        if (process.env.REACT_APP_ENV === 'production') {
            // パスワードの文字数制限 6文字以上、32文字以下
            if (password.length < 6 || password.length > 32) {
                newErrors.password = 'パスワードは6文字以上である必要があります';
            }
        }

        setErrors(newErrors);

        if (newErrors.email || newErrors.password) {
            return; // エラーがある場合、送信を中断
        }

        try {
            const result = await login(email, password);
            if (result.token) {
                const userResult = await getCurrentUser();
                if (userResult && userResult.data) {
                    setUser(userResult.data);
                    navigate('/welcome');
                } else {
                    setErrors({...newErrors, general: "ユーザーデータの取得に失敗しました。"});
                }
            } else {
                setErrors({...newErrors, general: "ログインに失敗しました。"});
            }
        } catch (error: any) {
            console.error("ログインエラー", error);
            if (error.response && error.response.status === 401) {
                try {
                        const userResult = await getCurrentUser();
                        if (userResult && userResult.data) {
                            setUser(userResult.data);
                            navigate('/welcome');
                        } else {
                            setErrors({...newErrors, general: "ユーザーデータの取得に失敗しました。"});
                        }
                } catch (refreshError) {
                    console.error("トークンリフレッシュエラー", refreshError);
                    setErrors({...newErrors, general: "ログインに失敗しました。再度ログインしてください。"});
                }
            } else {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
                setErrors({...newErrors, general: "ログインエラーが発生しました。"});
            }
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-title">Smart Pantry</div>
                    <div className="form-subtitle">メールアドレスでログイン</div>

                    {/* 全般的なエラー表示 */}
                    {errors.general && <div className="error-text general-error">{errors.general}</div>}

                    {/* メールアドレスフィールド */}
                    <label className="form-label" htmlFor="email">メールアドレス</label>
                    {errors.email && <div className="error-text">{errors.email}</div>}
                    <input
                        className={`form-input ${errors.email ? 'input-error' : ''}`}
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@example.com"
                        required
                    />

                    {/* パスワードフィールド */}
                    <label className="form-label" htmlFor="password">パスワード</label>
                    {errors.password && <div className="error-text">{errors.password}</div>}
                    <input
                        className={`form-input ${errors.password ? 'input-error' : ''}`}
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />

                    <button className="submit-button" type="submit">
                        <img
                            loading="lazy"
                            src={sendIcon}
                            className="button-icon"
                            alt="Submit Icon"
                        />
                        <span className="button-text">送信</span>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;

